.header {
  background: #1d73f4 url("~@/assets/patterns/blocks.svg");
  mask: none;
  position: relative;
  z-index: 2;
  -o-object-fit: cover;
  object-fit: cover;
  min-height: 350px;
  .title,
  .subtitle {
    color: #fff;
    strong {
      color: #fff;
    }
  }
  .container {
    padding: 50px 0px;
    @media only screen and (max-width: 1216px) {
      padding: 50px 20px;
    }
    ::v-deep .button {
      box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 10%)
    }
  }
}
.hero-body {
  padding: 80px 20px !important;
  width: 100%;
  .container {
    padding: 0;
  }
}
.table {
  margin: 0 auto;
  min-width: 100%;
  background: #fff;
  border: 2px solid #e2e9e9;
  border-bottom-width: 4px;
  td {
    padding: 20px 30px;
    font-size: 1.1rem;
  }
}
.header-image {
  position: relative;
  bottom: -80px;
  right: 0;
}
.windowbar {
  background: url('~@/assets/windowbar.svg') no-repeat;
  background-size: cover;
  height: 35px;
  width: 100%;
}
.anotherhand {
  font-size: 2.8rem;
}
