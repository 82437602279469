
@use "~@/assets/style/landing.scss";

.blurred {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}

.qr-container {
  position: relative;

  .button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

#chat {
  min-height: 400px !important;
  margin-top: 110px;
}

#chat-window {
  height: 80svh;
  background: darken(#f2f1f4, 2%);
  padding: 20px 40px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.05);
  @media only screen and (max-width: 1024px) {
    padding: 10px;
  }

  &::v-deep #chat-parent {
    max-height: unset !important;
    min-height: unset !important;
    margin-top: 100px;
    height: calc(100% - 100px);
    margin-left: 0 !important;
    margin-right: 0 !important;
    //max-height: calc(100% - 90px);
  }

  &::v-deep #chat {
    overflow-y: scroll;
    max-height: 100% !important;
    width: 100%;
  }

  #chat-navbar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    #dots {
      padding: 10px;

      .dot {
        height: 15px;
        width: 15px;
        margin-right: 5px;
        border-radius: 100%;

        &.green {
          background: #2dd634;
        }

        &.red {
          background: #f9004e;
        }

        &.yellow {
          background: #fcdc34;
        }
      }
    }
  }

  #chat-profile {
    position: absolute;
    top: 35px;
    left: 0;
    width: 100%;
    padding: 15px 40px;
    @media only screen and (max-width: 1024px) {
      padding: 15px 20px;
    }
    border-bottom: 1px solid darken(#f2f1f4, 10%);
    background: darken(#f2f1f4, 5%);
    height: 70px;

    img {
      max-height: 40px;
    }
  }
}
